<template>
  <v-card>
    <v-card-title>
      {{ "Chart Uploads" }}
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" outlined color="indigo" @click="add()">
        {{ "Add New" }}
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="browse"
      :search="search"
      :loading="loading"
      loading-text="LOADING.... PLEASE WAIT"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.index="{ item }">
        {{ item.index }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <span>
          <v-btn depressed text color="primary" @click="read(item.id)">
            <v-icon left dark>
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn depressed text color="error" @click="trash(item.id)">
            <v-icon left dark>
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </span>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogAdd" max-width="720px">
      <v-card class="pa-15">
        <chart-upload-add></chart-upload-add>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRead" max-width="720px">
      <v-card class="pa-15">
        <chart-upload-read :chartUploads="chartUploads"></chart-upload-read>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mdiPencil, mdiDelete, mdiClose } from "@mdi/js";
import permission from "@/directive/permission"; // Permission directive (v-permission)
import Request from "@/core/api/request";
import ChartUploadAdd from "./ChartUploadAdd.vue";
import ChartUploadRead from "./ChartUploadRead.vue";

export default {
  name: "ChartUploadBrowse",
  directives: { permission },
  components: { ChartUploadRead, ChartUploadAdd },

  data() {
    return {
      loading: true,
      search: "",
      browse: [],
      chartUploads: {},
      headers: [
        { text: "NO.", value: "index", sortable: true },
        { text: "Mission", value: "mission_id", sortable: false },
        { text: "Chart Upload Item", value: "chart_upload_item_id", sortable: false },
        { text: "Path", value: "path", sortable: false },
        { text: "Action.", value: "actions", sortable: false },
      ],
      icons: {
        mdiPencil,
        mdiDelete,
        mdiClose,
      },
      dialogAdd: false,
      dialogRead: false,
    };
  },

  computed: {
    perms() {
      return {
        read: this.$IYA.ACL.PERMISSION_USER_READ,
        add: this.$IYA.ACL.PERMISSION_USER_ADD,
        remove: this.$IYA.ACL.PERMISSION_USER_REMOVE,
      };
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const { data, status } = await Request.get(`${this.$IYA.URI.CHART_UPLOADS__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        this.loading = false;
        return;
      }
      data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.browse = data;
      this.loading = false;
    },

    read(param) {
      this.$router.push({ name: "ChartUpload-Read", params: { id: param } });
    },
    add() {
      this.dialogAdd = true;
    },
    trash(id) {
      this.$swal({
        title: `Are you sure?`,
        text: `You can't revert your action`,
        type: `warning`,
        showCancelButton: true,
        confirmButtonText: `Yes Delete it!`,
        cancelButtonText: `No, Keep it!`,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(result => {
        if (result.value) {
          this.destroy(id);
        } else {
          this.$swal(`Cancelled`, `Your records are still intact`, `info`);
        }
      });
    },
    async destroy(param) {
      await Request.destroy(`${this.$IYA.URI.CHART_UPLOADS__DELETE}`, { id: param }).then(response => {
        if (response.data.success) {
          this.$swal(`Deleted`, `You successfully deleted this record`, `success`);
          this.initialize();
        } else {
          this.$swal(`Failed !`, `Failed to remove record`, `error`);
        }
      });
    },
  },
};
</script>
